<template>
<div class="refill">
  <div class="refill__wrapper">
    <TheGuarantee>
      <template #default> {{ guaranteLabel }} </template>
      <template #additional>
        <OTooltip>
          {{ locale.refillTooltip }}
        </OTooltip>
      </template>
    </TheGuarantee>
    <OToggle
      v-model="isGuarantee"
      @input="onChangeToggle"
    />
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import OTooltip from '@/components/global/OTooltip.vue';
import OToggle from '@/components/global/OToggle.vue';
import TheGuarantee from '@/components/TheGuarantee.vue';

export default {
  components: {
    OTooltip,
    OToggle,
    TheGuarantee,
  },
  data() {
    return {
      isGuarantee: true,
    };
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    guaranteLabel() {
      return `${this.locale.refillLabel} +20%`;
    },
  },
  methods: {
    onChangeToggle(val) {
      this.$store.commit('refill/SET_IS_GUARANTEE', val);
      this.$store.dispatch('costs/calculateTotalCost');
    },
  },
};
</script>

<style lang="scss" scoped>
.refill {
  margin: 0;

  &__wrapper {
    display: flex;
    align-items: center;
    column-gap: 40px;

    @media (max-width: 1024px) {
      margin-top: 30px;
    }

    @media (max-width: 768px) {
      margin-top: 0;
      justify-content: flex-end;
    }

    @media (max-width: 524px) {
      margin: 24px 0;
      justify-content: space-between;
    }
  }
}
</style>
