export default (api, widgetJSApiInstance) => ({
  namespaced: true,
  state: {
    loading: false,
    allCostLoading: false,
    summary: 0,
    totalBonus: 0,
    subtotals: {},
    fee: {},
    paymentMethodBonus: {},
    couponBonus: {},
  },
  mutations: {
    SET_LOADING: (state, val) => { state.loading = val; },
    SET_ALL_COST_LOADING: (state, val) => { state.allCostLoading = val; },
    SET_SUMMARY: (state, val) => { state.summary = val; },
    SET_SUBTOTALS: (state, val) => { state.subtotals = val; },
    SET_TOTAL_BONUS: (state, val) => { state.totalBonus = val; },
    SET_PAYMENT_METHOD_BONUS: (state, val) => { state.paymentMethodBonus = val; },
    SET_COUPON_BONUS: (state, val) => { state.couponBonus = val; },
    SET_FEE: (state, val) => { state.fee = val; },
  },
  actions: {
    getCostAllServices({
      state,
      commit,
      rootGetters,
      rootState,
    }) {
      if (!rootGetters.selectedNetwork?.serviceTypes) return;

      commit('SET_ALL_COST_LOADING', true);
      const services = rootGetters.selectedNetwork.serviceTypes
        .filter((serviceType) => !serviceType.settings.calculableQuantity);

      api.calculateAllCostServices(
        services,
        rootGetters.selectedPaymentMethod.id,
        rootState.coupons.couponCode,
        rootState.url.url,
      )
        .then((response) => {
          const subtotals = { ...state.subtotals, ...response.subtotals };
          commit('SET_SUBTOTALS', subtotals);
        })
        .finally(() => {
          commit('SET_ALL_COST_LOADING', false);
        });
    },
    calculateTotalCost({
      state,
      commit,
      rootGetters,
      rootState,
    }) {
      commit('SET_LOADING', true);
      api.calculateTotalCost(
        rootGetters.selectedServiceTypes,
        rootGetters.selectedPaymentMethod.id,
        rootState.coupons.couponCode,
        rootState.url.url,
        rootState.refill.isGuarantee,
      )
        .then((response) => {
          const subtotals = { ...state.subtotals, ...response.subtotals };
          commit('SET_SUMMARY', response.summary);
          commit('SET_SUBTOTALS', subtotals);
          commit('SET_TOTAL_BONUS', response.totalBonus);
          commit('SET_PAYMENT_METHOD_BONUS', response.paymentMethodBonus);
          commit('SET_COUPON_BONUS', response.couponBonus);
          commit('SET_FEE', response.fee);

          widgetJSApiInstance.dispatchEvent('onCost', {
            summary: response.summary,
            subtotals: response.subtotals,
            totalBonus: response.totalBonus,
            paymentMethodBonus: response.paymentMethodBonus,
            couponBonus: response.couponBonus,
            fee: response.fee,
          });
        })
        .catch(() => {
          // request could be cancelled
        })
        .finally(() => {
          commit('SET_LOADING', false);
        });
    },
  },
});
