<template>
  <label
    class="toggle"
    :class="classNames"
  >
    <span class="toggle__btn">
      <input
        :checked="value"
        :disabled="disabled"
        type="checkbox"
        class="toggle__input"
        @change="onChange"
      >
      <span class="toggle__check-mark"></span>
    </span>
  </label>
</template>

<script>
export default {
  name: 'OToggle',
  props: {
    value: Boolean,
    disabled: Boolean,
  },
  computed: {
    classNames() {
      return {
        'toggle--disabled': this.disabled,
      };
    },
  },
  methods: {
    onChange(event) {
      this.$emit('input', event.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle {
  position: relative;
  width: 45px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &:not(.control--disabled)::before {
    content: "";
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
  }

  &__btn {
    position: relative;
    display: flex;
    height: 24px;
  }

  &__input {
    position: absolute;
    z-index: 2;
    bottom: -8px;
    left: -8px;
    display: block;
    width: 52px;
    height: 32px;
    appearance: none;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    outline-offset: -4px;
    cursor: pointer;
  }

  &__check-mark {
    position: relative;
    display: block;
    width: 45px;
    height: 24px;
    background-color: rgba(0, 0, 0, .38);
    border-radius: 16px;
    transition: 0.2s;

    &::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
      display: flex;
      background-color: #ffffff;
      border-radius: 16px;
      transition: transform 0.2s, width 0.2s, height 0.2s, background-color 0.2s;
    }
  }

  &__input:checked ~ &__check-mark {
    background-color: var(--selected-elements-color);
  }

  &__input:disabled ~ &__check-mark {
    opacity: 0.5;
  }

  &__input:checked ~ &__check-mark::before {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateX(21px);
  }
}
</style>
