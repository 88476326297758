export default {
  namespaced: true,
  state: {
    isGuarantee: true,
  },
  mutations: {
    SET_IS_GUARANTEE: (state, val) => {
      state.isGuarantee = val;
    },
  },
};
