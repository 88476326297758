<template>
  <div
    tabindex="0"
    class="tooltip"
    :class="classNames"
  >
    <IconQuestion class="tooltip__icon"/>
    <p
      v-if="$slots.default"
      class="tooltip__content"
    >
        <slot></slot>
    </p>
  </div>
</template>

<script>
import IconQuestion from '@/components/icons/IconQuestion.vue';

export default {
  name: 'OTooltip',
  components: {
    IconQuestion,
  },
  computed: {
    classNames() {
      return {
        'tooltip--content': this.$slots.default,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .tooltip {
    position: relative;
    margin: 0;
    max-height: 20px;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        inset: -5px;
      }

      &--content::before {
        content: "";
        position: absolute;
        bottom: calc(100% + 2px);
        left: 50%;
        margin-left: -4px;
        transform: rotate(180deg);
        border-width: 4px;
        border-style: solid;
        border-color: transparent transparent #212121 transparent;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.2s, opacity 0.2s;
      }

    &__content {
      position: absolute;
      left: 50%;
      bottom: 100%;
      transform: translate(-50%);
      margin: 10px;
      padding: 0;
      width: 284px;
      display: block;
      padding: 8px 16px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      text-align: left;
      color: #dfdfdf;
      border-radius: 8px;
      background-color: #212121;
      user-select: none;
      cursor: default;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.2s, opacity 0.2s;

      @media (max-width: 1024px) {
        left: -600%;
      }

      @media (max-width: 768px) {
        left: -200%;
      }

      @media (max-width: 524px) {
        left: 50%;
      }
    }

    &:hover,
    &:focus-visible {
      .tooltip__content {
        z-index: 10;
        visibility: visible;
        opacity: 1;
      }
    }

    &--content:hover,
    &--content:focus-visible {
      &::before {
        visibility: visible;
        opacity: 1;
      }
    }
  }
</style>
