import objectAssignByDictionary from '@/utils/objectAssignByDictionary';
import Target from './TargetAttachment';

const dictionary = {
  allowed_use_countries: 'allowedUseCountries',
  default_quantity: 'defaultQuantity',
  default_quantity_default_price: 'defaultQuantityDefaultPrice',
  default_quantity_price: 'defaultQuantityPrice',
  default_price: 'defaultPrice',
  max_quantity: 'max',
  min_quantity: 'min',
  quantity_step: 'step',
  pricing_per: 'pricingPer',
  drip_feed_is_allowed: 'dripFeedIsAllowed',
  example_link: 'exampleLink',
  geo: 'geo',
  guarantee: 'guarantee',
  refill: 'refill',
  requirements: 'requirements',
  short_description: 'shortDescription',
  speed: 'speed',
  start_time: 'startTime',
  targets: 'targets',
  calculable_quantity: 'calculableQuantity',
  package_contents: 'packageContents',
};
export default class Service {
  constructor(data) {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.type = '';
    this.price = '';
    this.pricingPer = 1000;
    this.allowedUseCountries = false;
    this.dripFeedIsAllowed = false;
    this.guaranteed = false;
    this.max = 1;
    this.min = 1;
    this.step = 1;
    this.defaultQuantity = 1;
    this.defaultPrice = 0;
    this.defaultQuantityDefaultPrice = '';
    this.defaultQuantityPrice = '';

    this.exampleLink = '';
    this.geo = '';
    this.guarantee = '';
    this.refill = '';
    this.requirements = '';
    this.shortDescription = '';
    this.speed = '';
    this.startTime = '';
    this.targets = [];
    this.calculableQuantity = false;
    this.packageContents = [];

    if (data) {
      objectAssignByDictionary(this, data, dictionary);
      this.id = Number(this.id);
      this.targets = data.targets?.length > 0
        ? data.targets.map((target) => new Target(target))
        : [];
    }
  }
}
