<template>
<svg
  width="16"
  height="16"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
<path
  fill-rule="evenodd"
  clip-rule="evenodd"
  d="M16.231 7.975a.666.666 0 0 1 .193.923l-4.533 6.934a.668.668
    0 0 1-1.007.127l-2.933-2.666a.667.667 0 0 1 .897-.987l2.355
    2.14L15.31 8.17a.666.666 0 0 1 .922-.194Z"
  fill="#4CAF50"
/>
<path
  fill-rule="evenodd"
  clip-rule="evenodd"
  d="M16.261 7.93a.72.72 0 0 1 .21.998l-4.534 6.933a.721.721 0 0
    1-1.09.139l-2.933-2.667a.721.721 0 0 1 .97-1.067l2.309 2.098
    4.07-6.225a.721.721 0 0 1 .998-.21Zm-5.058 6.517-2.355-2.14a.667.667
    0 0 0-.897.986l2.933 2.667a.667.667 0 0 0 1.007-.129l4.533-6.933a.666.666
    0 1 0-1.115-.73l-4.106 6.279Z"
  fill="#4CAF50"
/>
<path
  d="M21.09 10.91c0 5.55-3.84 10.74-9 12-5.16-1.26-9-6.45-9-12v-6l9-4 9 4v6Zm-9
  10c3.75-1 7-5.46 7-9.78V6.21l-7-3.12-7 3.12v4.92c0 4.32 3.25 8.78 7 9.78Z"
  fill="#4CAF50"
/>
</svg>

</template>

<script>
export default {
};
</script>
