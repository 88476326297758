<template>
  <div v-if="hasServices" :class="[
    'vast-service-order-now-service-type-card',
    {
      'vast-service-order-now-service-type-card--error': hasErrors,
      'vast-service-order-now-service-type-card--selected': isSelected,
      'vast-service-order-now-service-type-card--disabled': disabled,
      'vast-service-order-now-service-type-card--with-icons': theme.serviceTypeIconShow
    }
  ]">

    <Transition name="fade">
      <div
        v-if="isTargetAlertShown"
        class="vast-service-order-now-service-type-card__alert-targets"
        v-click-outside="hideTargetAlert"
      >
        {{ locale.alertTargets }}
      </div>
    </Transition>

    <div
      v-if="!isAvailableForUrl || !hasServices"
      class="vast-service-order-now-service-type-card__overlay"
    />
    <div
      v-if="!isAvailableForUrl"
      class="vast-service-order-now-warning"
    >
      <div class="vast-service-order-now-warning__text">
        {{ locale.serviceInactiveForUrl }}
      </div>
    </div>
    <div
      v-else-if="!hasServices"
      class="vast-service-order-now-warning"
    >
      <div class="vast-service-order-now-warning__text">
        {{ locale.noAvailableServices }}
      </div>
    </div>

    <OCheckBox
      v-model="isSelected"
      :disabled="disabled"
      class="vast-service-order-now-service-type-card__checkbox"
    />

    <div class="vast-service-order-now-service-type-card__column">
      <div
        class="vast-service-order-now-service-type-card__top cursor-pointer"
      >

        <div
          v-if="theme.serviceTypeIconShow"
          class="vast-service-order-now-service-type-card__icon-wrapper"
          @click="isSelected = !isSelected"
        >
          <img
            :src="serviceType.icon || require('@/assets/images/favorites.svg')"
            class="vast-service-order-now-service-type-card__icon"
          >
        </div>

        <div class="vast-service-order-now-service-type-card__label">
          <span
          class="vast-service-order-now-service-type-card__label-text"
            @click="isSelected = !isSelected"
          >
            {{ serviceTypeName }}
          </span>

          <span
            v-show="hasTargets"
            class="vast-service-order-now-service-type-card__has-targets"
            @click.prevent="showTargetAlert"
          >
            !
          </span>
        </div>

        <ExpandTransition>
          <div
            v-if="hasErrors"
            class="error"
          >
            {{ hasErrorLabelText }}
          </div>
        </ExpandTransition>

        <div class="vast-service-order-now-service-type-card__description">
          {{ serviceType.description }}
        </div>
      </div>

      <div
        v-if="hasServices"
        :class="[
          'vast-service-order-now-service-type-card__bottom',
          { 'icon-shown': theme.serviceTypeIconShow },
        ]"
      >
        <InputWithControl
          v-model="logQuantity"
          :min="selectedService.min"
          :max="selectedService.max"
          :calculableQuantity="selectedService.calculableQuantity"
          @blur="blurInputQuantity"
        />

        <div :class="[
          'vast-service-order-now-service-type-card__subtotal',
          { 'subtotal-discount': hasDiscount}
        ]">
          <div>
            <p class="vast-service-order-now-service-type-card__subtotal-default-cost">
              {{ defaultCost }}
            </p>

            <p class="vast-service-order-now-service-type-card__subtotal-cost">
              {{ cost }}
            </p>
          </div>

          <div class="vast-service-order-now-service-type-card__subtotal-loader">
            <OLoader v-if="allCostLoading" />
          </div>
        </div>

        <div class="vast-service-order-now-service-type-card__settings-container">
          <OBtn
            class="vast-service-order-now-service-type-card__settings"
            :disabled="disabled"
            text
            height="35"
            @click="openServiceTypeSettingsModal()"
          >
            <div class="vast-service-order-now-service-type-card__settings-text">
              {{ locale.settings }}
            </div>
          </OBtn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ServiceType from '@/classes/ServiceType';
import InputWithControl from '@/components/InputWithControl.vue';

export default {
  components: {
    InputWithControl,
  },
  inject: [
    'widgetJSApiInstance',
  ],
  props: {
    serviceType: ServiceType,
  },
  data() {
    return {
      isTargetAlertShown: false,
      isQuantityInput: false,
    };
  },
  computed: {
    ...mapGetters('config', [
      'locale',
    ]),
    ...mapState('config', [
      'theme',
    ]),
    ...mapState('costs', [
      'subtotals',
      'allCostLoading',
    ]),
    ...mapState([
      'errors',
    ]),
    ...mapState('url', [
      'availableServicesTypesIdsForUrl',
    ]),
    serviceTypeName() {
      const slicedNetworkName = this.serviceType.name.slice(0, 35);

      return slicedNetworkName.length < this.serviceType.name.lengt
        ? `${slicedNetworkName}...`
        : slicedNetworkName;
    },
    cost() {
      return this.subtotal?.cost || 0;
    },
    defaultCost() {
      return this.subtotal?.defaultCost;
    },
    hasDiscount() {
      return this.subtotal?.priceType === 'discount';
    },
    subtotal() {
      return this.subtotals[this.serviceType.id];
    },
    isAvailableForUrl() {
      if (this.availableServicesTypesIdsForUrl.length === 0) {
        return true;
      }

      return this.availableServicesTypesIdsForUrl.includes(this.serviceType.id);
    },
    hasServices() {
      return this.serviceType.services && this.serviceType.services.length;
    },
    disabled() {
      return !this.isAvailableForUrl || !this.hasServices;
    },
    selectedService() {
      return this.serviceType.services.find(
        (service) => service.id === this.serviceType.settings.selectedServiceId,
      );
    },
    minServiceQuantity() {
      return this.selectedService.min;
    },
    maxServiceQuantity() {
      return this.selectedService.max;
    },
    logQuantity: {
      get() {
        return this.serviceType.settings.quantity;
      },
      set(position) {
        this.isQuantityInput = true;
        this.serviceType.settings.quantity = position;
      },
    },
    hasTargets() {
      return this.serviceType.settings.targetableValues?.length > 0;
    },
    isSelected: {
      get() {
        return this.$store.state.selectedServiceTypesIds.includes(this.serviceType.id);
      },
      set(val) {
        if (this.isSelected === val) {
          return;
        }

        if (this.disabled) {
          return;
        }

        let selectedServiceTypesIds = this.$store.state.selectedServiceTypesIds.slice();

        if (val) {
          selectedServiceTypesIds.push(this.serviceType.id);
        } else {
          selectedServiceTypesIds = selectedServiceTypesIds.filter(
            (id) => id !== this.serviceType.id,
          );
        }

        this.widgetJSApiInstance.dispatchEvent('onCheckboxServiceTypeSelect', val);
        this.$store.commit('SET_SELECTED_SERVICE_TYPES_IDS', selectedServiceTypesIds);
      },
    },
    foundError() {
      return Object.entries(this.errors)
        .filter(
          ([key, value]) => {
            const id = Number(key.match(/\d+/gm)?.[0]);
            return id === this.serviceType.id && value.length;
          },
        );
    },
    hasErrors() {
      return this.foundError.length > 0;
    },
    hasErrorLabelText() {
      try {
        return this.foundError[0][1][0];
      } catch {
        return this.locale.serviceError;
      }
    },
  },
  methods: {
    openServiceTypeSettingsModal() {
      this.widgetJSApiInstance.dispatchEvent('onClickSettingsServiceType');
      this.isSelected = true;
      this.$store.commit('serviceSettingsModal/SET_SERVICE_TYPE', this.serviceType);
      this.$store.commit('serviceSettingsModal/SET_PREVIOUS_CONFIGURATION_SERVICE', { ...this.serviceType.settings });
      this.$store.commit('serviceSettingsModal/SET_IS_SHOWN', true);
    },
    showTargetAlert() {
      setTimeout(() => {
        this.isTargetAlertShown = true;
      });
    },
    hideTargetAlert() {
      this.isTargetAlertShown = false;
    },
    blurInputQuantity() {
      if (this.isQuantityInput) {
        this.widgetJSApiInstance.dispatchEvent('onEndInputQuantity');
        this.isQuantityInput = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vast-service-order-now-service-type-card {
  position: relative;
  width: 180px;
  margin-right: 24px;
  margin-bottom: 24px;

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: var(--main-background);
    opacity: 0.6;
  }

  &__icon-wrapper {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: var(--icon-color);
  }

  &__alert-targets {
    position: absolute;
    right: 0;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 124px;
    padding: 12px 15px;
    border-radius: 8px;
    box-shadow: 6px 6px 30px 0 rgba(0, 0, 0, 0.20);
    background-color: var(--modal-background);
    text-align: center;
    color: var(--modal-text-color);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
  }

  &__top {
    padding-left: 30px;
  }

  &__icon {
    max-width: 50px;
    max-height: 50px;
    opacity: .5;
  }

  &__checkbox {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 2;
  }

  &__column {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    color: var(--text-color);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    &-text {
      flex: 1;
    }
  }

  &__has-targets {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    margin-left: 5px;
    border-radius: 50%;
    background-color: var(--errors-color);
    color: #FFFFFF;
    cursor: pointer;
  }

  &__description {
    margin-top: 10px;
    font-size: 14px;
    opacity: .5;
  }

  &__subtotal {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 41px;
    margin-top: 12px;
    &-default-cost {
      display: none;
      margin: 0px;
      color: var(--text-color);
      text-decoration: line-through;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 17px;
    }
    &-cost {
      color: var(--text-color);
      margin: 0px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    &.subtotal-discount {
      .vast-service-order-now-service-type-card__subtotal-default-cost {
        display: block;
      }
      .vast-service-order-now-service-type-card__subtotal-cost {
        color: var(--errors-color);
      }
    }
  }

  &__settings {
    width: 100%;
    margin-top: 10px;
    border-radius: 8px !important;
  }

  &__settings-text {
    width: 100%;
  }

  &--error {
    .vast-service-order-now-service-type-card {
      &__label {
        color: #f00;
      }

      &__settings {
        border: 1px solid #f00 !important;
      }
    }
  }

  &--with-icons {
    .vast-service-order-now-service-type-card {
      &__top {
        min-height: 60px;
        padding-left: 60px;
      }

      &__quantity {
        padding-left: 84px;
      }
    }
  }
}

.vast-service-order-now-warning {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  &__text {
    padding: 20px 10px;
    color: var(--modal-text-color);
    font-size: 14px;
    text-align: center;
    background-color: var(--modal-background);
    border-radius: 8px;
    box-shadow: 6px 6px 30px 0 rgba(0, 0, 0, 0.20);
  }
}

.error {
  color: var(--errors-color);
  font-size: 14px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

@media (max-width: 1200px) {
  .vast-service-order-now-service-type-card {
    width: 168px;
  }
}

@media (max-width: 1024px) {
  .vast-service-order-now-service-type-card {
    width: 160px;
    margin-right: 16px;
  }
}

@media (max-width: 768px) {
  .vast-service-order-now-service-type-card {
    width: 148px;
  }
}
</style>
