<template>
<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  xmlns="http://www.w3.org/2000/svg"
>
  <g fill-rule="evenodd" clip-rule="evenodd" fill="currentColor">
    <path
      d="M10 2.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15ZM.833
      10a9.167 9.167 0 1 1 18.334 0A9.167 9.167 0 0 1 .833 10Z"
    />
    <path
      d="M9.527 6.796c-.117.093-.259.272-.45.65a.833.833 0 0
      1-1.488-.752c.226-.447.5-.886.904-1.205C8.926 5.146 9.43
      5 10 5c1.556 0 2.5 1.257 2.5 2.482 0 .592-.164
      1.096-.56 1.567-.276.326-.655.625-1.107.937v.847a.833.833
      0 1 1-1.666 0V9.094l.374-.247c.633-.418.951-.667
      1.124-.872.124-.147.168-.262.168-.493A.803.803 0 0 0 10
      6.667c-.264 0-.384.06-.473.129Zm.473 6.12c.46
      0 .833.374.833.834v.417a.833.833 0 0 1-1.666 0v-.417c0-.46.373-.833.833-.833Z" />
  </g>
</svg>
</template>

<script>
export default {
};
</script>
