import textToMapArray from '@/utils/textToMapArray';

function getDripFeed(serviceTypeSettings) {
  if (!serviceTypeSettings.dripFeedIsEnabled) {
    return null;
  }

  return {
    runs: serviceTypeSettings.dripFeedRuns,
    minutes_between_runs: serviceTypeSettings.dripFeedMinutesBetweenRuns,
  };
}

export default function createOrders(url, selectedServiceTypes, isGuarantee) {
  return selectedServiceTypes.reduce((acc, serviceType, index) => {
    const selectedService = serviceType.services
      .find((service) => service.id === serviceType.settings.selectedServiceId);

    const targetableValues = serviceType.settings.targetableValues.length
      ? serviceType.settings.targetableValues.map((obj) => (
        { ...obj }
      )) : [];
    const updatedTargetableValues = targetableValues.reduce((accumulator, orderItem) => {
      const viewType = orderItem.viewType;
      const values = orderItem.values;

      if (values && values.length > 0) {
        if (viewType === 'seo_keywords' || viewType === 'comments') {
          const originalValue = orderItem.values[0];
          const updatedItem = {
            ...orderItem,
            values: textToMapArray({ text: originalValue, viewType }),
          };
          accumulator.push(updatedItem);
        } else {
          accumulator.push(orderItem);
        }
      }

      return accumulator;
    }, []);

    acc.orders.push({
      url,
      service_id: serviceType.settings.selectedServiceId,
      quantity: selectedService.calculableQuantity
        ? null
        : serviceType.settings.quantity,
      drip_feed: getDripFeed(serviceType.settings),
      is_guarantee: isGuarantee,
      targetable_values: updatedTargetableValues.length !== 0
        ? updatedTargetableValues.map((target) => ({
          target_id: target.targetId,
          values: target.values,
        })) : [],
    });

    acc.orderIndexToServiceTypeIdMap[index] = serviceType.id;

    return acc;
  }, {
    orderIndexToServiceTypeIdMap: {},
    orders: [],
  });
}
