<template>
<div class="guarantee">
  <div class="guarantee__label">
    <IconGuarantee  class="guarantee__icon"/>
    <span class="guarantee__text">
      <slot name="default"></slot>
    </span>
  </div>
  <div class="guarantee__additional-block">
    <slot name="additional"></slot>
  </div>
</div>
</template>

<script>

import IconGuarantee from '@/components/icons/IconGuarantee.vue';

export default {
  components: {
    IconGuarantee,
  },
};
</script>

<style lang="scss" scoped>
.guarantee {
  display: flex;
  align-items: center;
  column-gap: 12px;

  &__label {
    min-width: 127px;
    padding: 6px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background-color: #E1FBE4;
    border-radius: 100px;
  }

  &__text {
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
    color: #4CAF50;
    white-space: nowrap;
    user-select: none;
  }

  &__icon + &__text {
    margin-left: 4px;
  }
}
</style>
