var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'vast-service-order-now-service-item',
    { 'vast-service-order-now-service-item--opened': _vm.opened } ]},[_c('ORadio',{attrs:{"value":_vm.service.id},model:{value:(_vm.Value),callback:function ($$v) {_vm.Value=$$v},expression:"Value"}}),_c('div',{staticClass:"vast-service-order-now-service-item__main",on:{"click":function($event){_vm.opened = !_vm.opened}}},[_c('div',{staticClass:"cursor-pointer"},[_c('div',{staticClass:"vast-service-order-now-service-item__label"},[_vm._v(" "+_vm._s(_vm.service.name)+" ")]),_c('div',{staticClass:"vast-service-order-now-service-item__cost"},[_vm._v(" "+_vm._s(_vm.cost)+" / "+_vm._s(_vm.service.defaultQuantity)+" ")])]),_c('ExpandTransition',[(_vm.opened)?_c('PackageDescription',{attrs:{"service":_vm.service}}):_vm._e()],1)],1),_c('button',{staticClass:"vast-service-order-now-service-item__more",on:{"click":function($event){_vm.opened = !_vm.opened}}},[_c('span',{class:[
        'vast-service-order-now-service-item__more-icon',
        {
          'vast-service-order-now-service-item__more-icon--opened': _vm.opened,
        }
      ]},[_c('IconChevron',{attrs:{"width":"11px"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }